<template>
    <div class="contain">
        <HeaderMenu></HeaderMenu>
        <div class="main">
            <div class="main-left">
                <CustomBox></CustomBox>
            </div>
            <div class="main-right">
                <ChatBox></ChatBox>
            </div>
        </div>
        <BottomMenu></BottomMenu>
    </div>
</template>

<script>
import CustomBox from '@/components/CustomBox.vue';
import HeaderMenu from '@/components/HeaderMenu.vue';
import ChatBox from '@/components/ChatBox.vue';
import BottomMenu from '@/components/BottomMenu.vue';
import moment from 'moment'; 

export default {
    data() {
        return {
            messages: [
                { mode: "text", obj: true, time: moment().format("HH:mm:ss"), text: "同学你好，我是数学派迷你小唤机器人？" },
                { mode: "text", obj: true, time: moment().format("HH:mm:ss"), text: "请告诉你要翻译的语句吧！" },
            ],
            msg: ""
        };
    },
    mounted() {
    },
    components: { HeaderMenu, CustomBox, ChatBox, BottomMenu }
}
</script>

<style scoped>
.main {
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    min-height: calc(100vh - 60px);
}

.main-left {
    width: 525px;
}

.main-right {
    flex: 1;
    margin-left: 20px;
}

</style>