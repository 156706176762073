<template>
    <div class="contain manage-contain">
        <HeaderManage></HeaderManage>
        <div class="main">
            <NavigateMenu active="/manage/profile"></NavigateMenu>
            <div class="contain-box">
                <el-descriptions class="profile-box" :column="2" border>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-user"></i>
                            我的账号
                        </template>
                        {{ user.username }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-mobile-phone"></i>
                            我的手机
                        </template>
                        {{ user.mobile }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-monitor"></i>
                            我的邮箱
                        </template>
                        {{ user.mail }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            我的昵称
                        </template>
                        <el-tag size="small">
                            {{ user.nickname ? user.nickname : '空' }}
                        </el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-location-outline"></i>
                            我的最近登录信息
                        </template>
                        {{user.logintime}} {{ user.address }}
                    </el-descriptions-item>
                </el-descriptions>

                <div class="table-box">
                    <el-table height="360" width="100" :data="tableData" border>
                        <el-table-column label="管理员昵称" width="120">
                            <template slot-scope="scope">
                                <el-popover trigger="hover" placement="top">
                                    <p style="margin: 5px 0;">管理员昵称: {{ scope.row.nickname }}</p>
                                    <p style="margin-bottom: 5px;">管理员账号: {{ scope.row.username }}</p>
                                    <p style="margin-bottom: 5px;">管理员手机: {{ scope.row.mobile }}</p>
                                    <p style="margin-bottom: 5px;">管理员邮箱: {{ scope.row.mail }}</p>
                                    <div slot="reference" class="name-wrapper">
                                        <el-tag size="medium">{{ scope.row.nickname }}</el-tag>
                                    </div>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="IP 地址" width="120">
                        </el-table-column>
                        <el-table-column prop="logintime" label="最近登录时间">
                        </el-table-column>
                        <el-table-column label="操作" width="90" align="center">
                            <template slot-scope="scope">
                                <el-button size="mini" type="danger" @click="deleteUser(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <div class="user-insert-box">
                <el-form ref="form" :model="form">
                    <el-form-item label="管理员昵称">
                        <el-input class="custom-textarea" :style="{ textAlign: 'justify' }" type="text"
                            placeholder="请填写管理员昵称" v-model="form.nickname" resize="none" maxlength=128></el-input>
                    </el-form-item>

                    <el-form-item label="管理员账号">
                        <el-input class="custom-textarea" :style="{ textAlign: 'justify' }" type="text"
                            placeholder="请填写管理员账号" v-model="form.username" resize="none" maxlength=128></el-input>
                    </el-form-item>

                    <el-form-item label="管理员密码">
                        <el-input class="custom-textarea" :style="{ textAlign: 'justify' }" type="text"
                            placeholder="请填写管理员密码" v-model="form.password" resize="none" maxlength=128></el-input>
                    </el-form-item>

                    <el-form-item label="管理员手机">
                        <el-input class="custom-textarea" :style="{ textAlign: 'justify' }" type="text"
                            placeholder="请填写管理员手机" v-model="form.mobile" resize="none" maxlength=128></el-input>
                    </el-form-item>

                    <el-form-item label="管理员邮箱">
                        <el-input class="custom-textarea" :style="{ textAlign: 'justify' }" type="text"
                            placeholder="请填写管理员邮箱" v-model="form.mail" resize="none" maxlength=128></el-input>
                    </el-form-item>


                </el-form>
                <div class="el-form-bottom">
                    <el-button type="primary" @click="insertUser" :disabled="loading">创建管理员账号</el-button>
                    <el-button type="danger" @click="deleteUserData(true)">清空</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderManage from '@/components/manages/HeaderManage.vue';
import NavigateMenu from '@/components/manages/NavigateMenu.vue';
import Api from "@/apis"
export default {
    data() {
        return {
            user: {},
            form: {
                username: "",
                password: "",
                mobile: "",
                mail: "",
                nickname: ""
            },
            loading: false,
            tableData: []
        };
    },
    methods: {
        deleteUser(user) {
            this.$prompt('请填写超级管理员密钥？', '预醒网络', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(({ value }) => {
                if (this.loading) {
                    return
                } else {
                    this.loading = true
                }
                Api.manageUserDelete({
                    username: user.username,
                    secret: value
                }).then(res => {
                    if (res.data.code == 200) {
                        this.tableData = this.tableData.filter(originUser => originUser.username !== user.username);
                        this.$message.success(res.data.message)
                    } else {
                        this.$message.error(res.data.message)
                    }
                }).catch(() => {
                    this.$message.warning("服务器异常")
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });
            });
        },
        deleteUserData(openMessage = false) {
            this.form = {
                username: "",
                password: "",
                mobile: "",
                mail: "",
                nickname: ""
            }
            if (openMessage) {
                this.$message.success("清空管理员信息数据成功")
            }
        },
        insertUser() {
            if (!this.form.username || !this.form.nickname || !this.form.mobile || !this.form.password || !this.form.mail) {
                this.$message.warning("管理员信息空缺")
                return
            }
            this.$prompt('请填写超级管理员密钥？', '预醒网络', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(({ value }) => {
                if (this.loading) {
                    return
                } else {
                    this.loading = true
                }
                Api.manageRegister({
                    ...this.form,
                    secret: value
                }).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success(res.data.message)
                        this.selectUserData()
                        this.deleteUserData()
                    } else {
                        this.$message.error(res.data.message)
                    }
                    this.loading = false
                }).catch(() => {
                    this.$message.warning("服务器异常")
                    this.loading = false
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });
            });

        },
        selectUserData() {
            Api.manageUserSelect().then(res => {
                if (res.data.code == 200) {
                    this.tableData = res.data.data
                    this.$message.success(res.data.message)
                } else {
                    this.$message.error(res.data.message)
                }
            }).catch(() => {
                this.$message.warning("服务器异常")
            })
        }
    },
    beforeCreate() {

    },
    mounted() {
        this.user = JSON.parse(sessionStorage.getItem("license"))
        this.selectUserData()
    },
    components: { HeaderManage, NavigateMenu }
}
</script>

<style scoped>
.main {
    box-sizing: border-box;
    display: flex;
    min-height: calc(100vh - 60px);
    justify-content: space-between;
}

.contain-box {
    flex: 1;
    margin: 20px;
    height: 600px;
    margin-right: 0;
    display: flex;
    flex-direction: column;
}


.profile-box {
    padding: 20px;
    height: 180px;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
}

.el-descriptions-item {
    box-sizing: border-box;
}

.table-box {
    height: 360px;
    margin-top: 20px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
}

.user-insert-box {
    margin: 20px;
    background-color: #FAFBFE;
    width: 400px;
    height: 600px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
}

.el-form {
    padding: 20px;
    box-sizing: border-box;
    height: calc(600px - 80px);
    overflow-y: scroll;
    padding-bottom: 0;
}

.el-table::v-deep tr {
    box-sizing: border-box;
    height: 50px;
}

.el-table::v-deep td {
    box-sizing: border-box;
    padding: 0 !important;
}

.el-form-item {
    margin-bottom: 20px;
}

.el-form-bottom {
    background-color: rgb(255, 255, 255);
    height: 40px;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    padding: 20px;
    padding-bottom: 20px;
    text-align: right;
}

.el-input {
    text-align: justify;
}

.custom-textarea::v-deep .el-textarea__inner {
    text-align: justify;
    line-height: 20px;
}
</style>