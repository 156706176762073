<template>
    <div class="cat-window">
        <div class="cat-content" id="cat-content-window">
            <div v-for="(msg, index) in messageList" :key="index">
                <div class="message robot" v-if="msg.belong == 'BOT'">
                    <img class="message-obj-image" src="@/assets/图标.jpg" alt="">
                    <div>{{ msg.content }}</div>
                    <span>{{ msg.time }}</span>
                </div>

                <div class="message mine" v-if="msg.belong == 'ME'">
                    <img class="message-obj-image" src="@/assets/边缘骇客实验室.png" alt="">
                    <div>{{ msg.content }}</div>
                    <span>{{ msg.time }}</span>
                </div>
            </div>

            <div class="message-button" v-if="buttonMenu">
                <div class="button" @click="answer('我想要进行测验英语单词！')">
                    <svg t="1703049661268" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10381" width="20" height="20"><path d="M455.68 404.032l91.264 529.152c0 0 67.456-69.44 123.136-117.888l122.432 163.904c4.928 6.656 15.68 7.104 23.872 1.088l52.288-38.208c8.256-6.016 10.944-16.32 5.952-22.976l-119.104-159.424c62.208-25.088 164.672-53.632 164.672-53.632L455.68 404.032zM308.352 648.384l-135.872 99.328c-20.544 15.04-24.256 43.968-8 65.408 16.256 21.376 46.272 27.008 66.752 12.032l135.872-99.328c20.992-15.36 24.512-45.504 8.256-66.88C359.168 637.504 329.344 633.024 308.352 648.384zM949.696 238.976c-16.256-21.376-45.632-26.176-67.072-10.496l-134.912 98.688c-21.44 15.68-25.152 44.672-8.896 66.048 16.256 21.376 46.272 27.008 67.712 11.328l134.912-98.688C962.88 290.176 965.952 260.352 949.696 238.976zM319.296 136.832c-15.936-20.928-45.248-25.728-66.752-10.048-20.096 14.72-24.256 43.968-8.32 64.896l105.536 138.816c15.936 20.992 45.696 25.408 65.792 10.688 21.44-15.68 25.216-44.608 9.28-65.6L319.296 136.832zM585.792 301.76c26.176 4.224 50.24-13.376 53.632-39.232l21.184-167.808c3.392-25.792-14.976-49.984-41.536-54.656-26.176-4.224-50.24 13.376-53.632 39.168l-21.248 167.872C540.928 272.96 559.296 297.088 585.792 301.76zM329.728 489.024c2.56-25.92-15.808-50.048-41.536-54.656l-170.048-27.968c-27.072-3.584-50.688 13.696-53.632 39.232-3.904 26.944 14.464 51.072 41.536 54.656l170.048 27.968C301.824 532.736 325.504 515.456 329.728 489.024z" fill="#A7B0C7" p-id="10382"></path></svg>
                    <span>我想要进行测验英语单词！</span>
                </div>
                <div class="button" @click="answer('我想要进行翻译英语语段！')">
                    <svg t="1703049661268" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10381" width="20" height="20"><path d="M455.68 404.032l91.264 529.152c0 0 67.456-69.44 123.136-117.888l122.432 163.904c4.928 6.656 15.68 7.104 23.872 1.088l52.288-38.208c8.256-6.016 10.944-16.32 5.952-22.976l-119.104-159.424c62.208-25.088 164.672-53.632 164.672-53.632L455.68 404.032zM308.352 648.384l-135.872 99.328c-20.544 15.04-24.256 43.968-8 65.408 16.256 21.376 46.272 27.008 66.752 12.032l135.872-99.328c20.992-15.36 24.512-45.504 8.256-66.88C359.168 637.504 329.344 633.024 308.352 648.384zM949.696 238.976c-16.256-21.376-45.632-26.176-67.072-10.496l-134.912 98.688c-21.44 15.68-25.152 44.672-8.896 66.048 16.256 21.376 46.272 27.008 67.712 11.328l134.912-98.688C962.88 290.176 965.952 260.352 949.696 238.976zM319.296 136.832c-15.936-20.928-45.248-25.728-66.752-10.048-20.096 14.72-24.256 43.968-8.32 64.896l105.536 138.816c15.936 20.992 45.696 25.408 65.792 10.688 21.44-15.68 25.216-44.608 9.28-65.6L319.296 136.832zM585.792 301.76c26.176 4.224 50.24-13.376 53.632-39.232l21.184-167.808c3.392-25.792-14.976-49.984-41.536-54.656-26.176-4.224-50.24 13.376-53.632 39.168l-21.248 167.872C540.928 272.96 559.296 297.088 585.792 301.76zM329.728 489.024c2.56-25.92-15.808-50.048-41.536-54.656l-170.048-27.968c-27.072-3.584-50.688 13.696-53.632 39.232-3.904 26.944 14.464 51.072 41.536 54.656l170.048 27.968C301.824 532.736 325.504 515.456 329.728 489.024z" fill="#A7B0C7" p-id="10382"></path></svg>
                    <span>我想要进行翻译英语语段！</span>
                </div>
            </div>
        </div>
        <div class="cat-box">
            <input type="text" maxlength="100" :disabled="inputLock" placeholder="请填写你的消息内容（按回车键进行快捷键发送消息）"
                v-model="response">
            <button :disabled="inputLock" @click="response = ''">清空消息</button>
            <button :disabled="inputLock" @click="sendTranslateMessage">发送消息</button>
        </div>
    </div>
</template>

<script>
import Api from '@/apis';
import moment from 'moment'
export default {
    data() {
        return {
            messageList: [],
            serial: 0,
            buttonMenu: false,
            response: "",
            inputLock: true
        }
    },
    methods: {
        scrollBottom() {
            const chatContainer = document.getElementById('cat-content-window');
            chatContainer.scrollTop = chatContainer.scrollHeight;
        },
        answer(content) {
            if (this.timer) { return }
            this.buttonMenu = false
            this.launchTimer("ME", content, () => {
                if (content == "我想要进行翻译英语语段！") {
                    this.launchTimer("BOT", "欢迎使用迷你英语单词猫语段翻译功能！请填写你想要翻译的英语语段然后按发送消息按钮进行翻译！", () => {
                        this.inputLock = false
                    })
                }
                if (content == "我想要进行测验英语单词！") {
                    this.launchTimer("BOT", "很抱歉这位同学暂时不能使用当前功能！迷你英语单词猫的测验英语单词功能正在内测中！请您敬请期待新功能更新通知！", () => { })
                }
            })
        },
        sendTranslateMessage() {
            if (this.timer) { return }
            if (!this.response) { return }
            this.inputLock = true
            this.launchTimer("ME", this.response, () => {
                Api.customTranslate({sourceText: this.response}).then(res => {
                    if (res.data.code == 200 && res.data.msg == "success" ) {
                        this.launchTimer("BOT", res.data.result.targetText, () => {
                            this.response = ""
                            this.inputLock = false
                        })
                    } else {
                        this.launchTimer("BOT", "非常抱歉迷你英语单词猫服务器故障！", () => {})
                    }
                }).catch(() => {
                    this.launchTimer("BOT", "非常抱歉您的网络故障！请自行检查您的网络问题！", () => {})
                })
            })
        },
        launchTimer(bot, content, func) {
            let msg = { belong: bot, content: "", time: moment().format("YYYY.MM.DD HH:mm:ss") }
            this.messageList.push(msg)
            let len = 0
            this.timer = setInterval(() => {
                if (len >= content.length) {
                    this.serial++
                    clearInterval(this.timer)
                    this.timer = null
                    func()
                } else {
                    len = len + 1
                    this.messageList[this.serial].content = content.substring(0, len)
                }
            }, 100)
        }
    },
    updated() {
        this.scrollBottom()
    },
    mounted() {
        this.launchTimer("BOT", "这位同学您好！我是迷你英语单词猫！请问您选择您想要的迷你英语单词猫相关服务内容？", () => {
            this.buttonMenu = true
        })
    },
    beforeDestroy() {
        if (this.timer) { clearInterval(this.timer) }
    }
}
</script>

<style scoped>
.cat-window {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 600px;
}

.cat-content {
    height: 550px;
    box-sizing: border-box;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 10px 0;
    text-align: right;
}

.cat-box {
    background-color: rgb(255, 255, 255);
    height: 50px;
    display: flex;
    justify-content: space-between;
}

.cat-box input {
    padding: 0 20px;
    border: none;
    outline: none;
    flex: 1;
    font-family: "mathspie";
}

.cat-box button {
    font-family: "mathspie";
    padding: 0 20px;
    background-color: #3D9AD4;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;
    border: none;
    border-left: 2px solid white;
}

.cat-box button:hover {
    background-color: #296c96;
}

.message {
    display: flex;
    padding: 20px 20px;
    text-align: left;
}

.mine {
    flex-direction: row-reverse;
}

.message-obj-image {
    height: 35px;
    width: 35px;
    border-radius: 35px;
}

.message div {
    flex-shrink: 0;
    max-width: 400px;
    line-height: 25px;
    background-color: white;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 13px;
    color: #6c7894;
    margin: 0 20px;
    text-align: justify;
}

.message span {
    line-height: 35px;
    color: #929eba;
    font-size: 12px;
}

.message-button {
    padding: 20px 20px;
    height: 35px;
}

.button {
    display: inline-flex;
    padding: 0 15px;
    height: 35px;
    border-radius: 5px;
    align-items: center;
    border: 2px solid #A7B0C7;
    color: #A7B0C7;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    justify-content: space-between;
}

.button svg {
    margin-right: 15px;
}

.button:hover {
    background-color: #e5f0f9;
}
</style>