<template>
    <div class="header">
        <img class="header-logo" src="@/assets/图标.jpg" alt="" @click="navigateIndex">
        <div class="header-title" @click="navigateIndex">迷你英语单词猫</div>
        <a class="header-button" v-for="(uri, index) in uris" :key="index" :href="uri.uri" target="_blank">{{ uri.name
        }}</a>
        <div class="login-button" v-if="user" @click="navigateManage">
            <img class="login-button-img"  :src="userAvatarFormat(user.username)" alt="">
            <div>管理员：{{ user.username }}</div>
        </div>
        <div class="login-button" v-else @click="navigateLogin">
            <img class="login-button-img" src="./../assets/默认头像.png" alt="">
            <div>管理员登录</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: {},
            uris: [
                { name: "直播互动助手", uri: "https://livehelper.willwaking.com/" },
                { name: "解题星球", uri: "https://planet.mslmsxp.com/" },
                { name: "编程英语研究网", uri: "https://blog.willwaking.com/" },
                { name: "亿题云资料宝库", uri: "https://cloud.mslmsxp.com/" },
                { name: "秒数联盟数学派", uri: "https://www.mslmsxp.com/" },
                { name: "醒语开源英语网", uri: "https://word.willwaking.com/" }
            ]
        }
    },
    props: {
        title: {
            default: "旗舰店"
        }
    },
    methods: {
        navigateManage() {
            if (this.$route.path !== '/manage') {
                this.$router.push('/manage');
            }
        },
        navigateIndex() {
            if (this.$route.path !== '/') {
                this.$router.push('/');
            }
        },
        navigateLogin() {
            if (this.$route.path !== '/login') {
                this.$router.push('/login');
            }
        },
        userAvatarFormat(qq) {
            return `https://q4.qlogo.cn/headimg_dl?dst_uin=${qq}&spec=640`
        },
    },
    mounted() {
        this.user = JSON.parse(sessionStorage.getItem("license"))
    }
}
</script>


<style scoped>
.header {
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
    position: sticky;
    top: 0;
}

.header:hover {
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    background-color: #FFFFFF;
}

.header-logo {
    width: 40px;
    height: 40px;
    display: flex;
    cursor: pointer;
    border-radius: 5px;
    cursor: pointer;
}

.header-title {
    margin-left: 20px;
    margin-right: auto;
    cursor: pointer;
}

.header-button {
    margin-left: 20px;
    padding: 0 15px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    border: 2px solid #0079BA;
    box-sizing: border-box;
    color: #0079BA;
    cursor: pointer;
    text-decoration: none;
}

.header-button:hover {
    background-color: #0079BA;
    color: white;
}

.login-button {
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #FFFFFF;
    cursor: pointer;
    margin-left: 20px;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    color: #0079BA;
}

.login-button-img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    box-sizing: border-box;
    display: block;
    padding: 5px;
}

.login-button div {
    padding: 0 20px;
}

</style>