<template>
    <div class="contain manage-contain">
        <HeaderManage></HeaderManage>
        <div class="main">
            <NavigateMenu active="/manage/problem"></NavigateMenu>
            <div class="table-box">
                <el-table :data="tableData" border fit>
                    <el-table-column prop="serial" label="题目编码" :resizable="false">
                    </el-table-column>
                    <el-table-column prop="username" label="题目管理员" :resizable="false">
                    </el-table-column>
                    <el-table-column prop="date" label="题目日期" :resizable="false">
                    </el-table-column>
                    <el-table-column label="操作" width="160" align="center" :resizable="false">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="showProblem(scope.row)">查看</el-button>
                            <el-button size="mini" type="danger" @click="deleteProblem(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="table-box-pagination">
                    <el-pagination @current-change="changeCurrentPage" small background layout="prev, pager, next, jumper"
                        :page-count="page.pageCount" :total="page.tatal">
                    </el-pagination>
                </div>
            </div>
            <div class="problem-box">
                <div class="problem-content">
                    <div class="problem-box-title">当前题目: {{ currentProblem.serial ? currentProblem.serial : '客户端数据异常' }}
                    </div>
                    <div class="problem-content-data">
                        题目内容：{{ currentProblem.content ? currentProblem.content : '客户端数据异常' }}
                    </div>
                </div>
                <div class="problem-option">
                    <div class="problem-option-item" v-for="option, index in currentProblem.option" :key="index">
                        <div class="problem-option-item-tip">
                            <div class="problem-option-item-tip-value" style="background-color: #21D789;"
                                v-if="option == currentProblem.answer">
                                {{ ['A', 'B', 'C', 'D'][index] }}
                            </div>
                            <div class="problem-option-item-tip-value" v-else>
                                {{ ['A', 'B', 'C', 'D'][index] }}
                            </div>
                        </div>
                        <div class="problem-option-item-content">
                            {{ option }}
                        </div>
                    </div>
                </div>
                <div class="problem-content">
                    <div class="problem-content-data" style="color: #F56C6C">
                        题目答案：{{ currentProblem.answer ? currentProblem.answer : '客户端数据异常' }}
                    </div>
                </div>
                <div class="problem-content">
                    <div class="problem-content-data" style="color: #278DC5">
                        题目解析：{{ currentProblem.solution ? currentProblem.solution : '客户端数据异常' }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/apis';
import HeaderManage from '@/components/manages/HeaderManage.vue';
import NavigateMenu from '@/components/manages/NavigateMenu.vue';

export default {
    data() {
        return {
            tableData: [],
            currentProblem: {},
            page: {
                pageCount: 0
            },
            loading: false
        };
    },
    methods: {
        deleteProblem(problem) {
            this.$confirm('请问是否删除当前题目？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Api.manageProblemDelete({
                    serial: problem.serial
                }).then(res => {
                    if (res.data.code == 200) {
                        this.tableData = this.tableData.filter(originProblem => originProblem.serial !== problem.serial);
                        this.$message.success(res.data.message)
                    } else {
                        this.$message.error(res.data.message)
                    }
                }).catch(() => {
                    this.$message.warning("服务器异常")
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '操作取消'
                });
            });

        },
        filterProblemTable(problemList) {
            return problemList
        },
        showProblem(problem) {
            this.currentProblem = problem
            this.$message.info("题目信息加载成功")
        },
        changeCurrentPage(page) {
            this.selectProblemData(page)
        },
        selectProblemData(page, isInit = false) {
            Api.manageProblemSelect({ page }).then(res => {
                if (res.data.code == 200) {
                    this.tableData = res.data.data.data
                    this.page.pageCount = res.data.data.count
                    this.page.tatal = res.data.data.total
                    this.$message.success(res.data.message)
                    if (isInit) {
                        if (this.tableData) {
                            this.currentProblem = this.tableData[0]
                        }
                    }
                }
            }).catch(() => {
                this.$message.warning("服务器异常")
            })
        }
    },
    beforeCreate() {

    },
    mounted() {
        this.selectProblemData(1, true)
    },
    components: { HeaderManage, NavigateMenu }
}
</script>

<style scoped>
.main {
    box-sizing: border-box;
    display: flex;
    min-height: calc(100vh - 60px);
}

.table-box {
    flex: 1;
    margin: 20px;
    height: 600px;
    background-color: #FFFFFF;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
}

.el-table {
    height: 560px;
}

.table-box-pagination {
    height: 50px;
    display: flex;
    align-items: center;
}

.el-table::v-deep tr {
    box-sizing: border-box;
    height: 50px;
}

.el-table::v-deep td {
    box-sizing: border-box;
    padding: 0 !important;
}

.problem-box {
    margin: 20px;
    background-color: #FAFBFE;
    width: 400px;
    height: 600px;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    overflow-y: scroll;
}

.problem-content {
    border-radius: 5px;
    line-height: 25px;
    box-sizing: border-box;
    margin: 20px;
    background-color: rgba(255, 255, 255, 0.5);
}

.problem-box-title {
    padding: 0 10px;
    height: 25px;
    display: inline-flex;
    align-items: center;
    background-color: #d2d8e8;
    color: #ffffff;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 12px;
}

.problem-content-data {
    background-color: #F5F7FA;
    padding: 5px 10px;
    font-size: 12px;
    text-align: justify;
    border-radius: 5px;
}

.problem-option {
    margin-top: auto;
    padding: 0 20px;
    margin-bottom: 15px;
}

.problem-option-item {
    margin: 10px auto;
    margin-top: 0;
    border-radius: 5px;
    min-height: 45px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-basis: 0;
    flex: 1;
}

.problem-option-item-tip {
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.problem-option-item-tip-value {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D1DFEC;
    color: #ffffff;
    border-radius: 30px;
}

.problem-option-item-content {
    padding: 5px 10px;
    padding-left: 0;
    color: #7594AF;
    font-size: 12px;
    line-height: 25px;
    text-align: justify;
    flex: 1;
}</style>