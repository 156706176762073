<template>
    <div class="navigate">
        <el-menu :default-active="active" active-text-color="#ffd04b">
            <el-menu-item index="/manage/problem" @click="navigateMenu('/manage/problem')">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>综合题库管理</span>
                </template>
            </el-menu-item>
            <el-menu-item index="/manage/ai" @click="navigateMenu('/manage/ai')">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>智能命题系统</span>
                </template>
            </el-menu-item>
            <el-menu-item index="/manage/exercise" @click="navigateMenu('/manage/exercise')">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>在线英语刷题</span>
                </template>
            </el-menu-item>
            <el-menu-item index="/manage/profile" @click="navigateMenu('/manage/profile')">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>管理信息预览</span>
                </template>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: ["active"],
    methods: {
        navigateMenu(path) {
            if (this.$route.path != path) {
                this.$router.replace(path);
            }
        }
    },
    mounted() {

    }
}
</script>


<style scoped>
.navigate {
    width: 200px;
    min-height: calc(100vh - 60px);
    background-color: #3D4454;
}

.el-menu {
    height: 100%;
    background-color: #262F3E;
}

.el-menu-item {
    background-color: #3D4454;
    color: #E9E9E9;
}

.el-menu-item:hover {
    background-color: #626A7D;
}
</style>