<template>
    <div class="phone-contain">
        <div class="header">
            <img class="header-logo" src="@/assets/图标.jpg" alt="">
            <div class="header-title">迷你英语单词猫</div>
            <a class="header-button" href="https://www.mslmsxp.com" target="_blank">数学派</a>
        </div>
        <div class="content">
            <img src="@/assets/图标.jpg" alt="">
            <div class="content-title">迷你英语单词猫</div>
            <div class="content-tip">
                迷你英语单词猫 MINIMIAO BOT 1.0
                是上海预醒网络科技有限公司旗下边缘骇客编程实验室制作的英语单词综合机器人，结合数学派迷你英语单词猫网站进行拓展服务，给初高中或者大学的同学们提供全新的英语学习新氛围。边缘骇客编程实验室在接下来的时间继续给同学们研制新的学习产品，对于任何疑问都可以在反馈空间和官方面对面进行沟通，希望同学们能保持热爱的激情在迷你英语单词猫去学习英语单词，你我皆是黑马骐骥而敬庆前缘。
            </div>
            <div class="content-button">
                <button @click="$router.replace('/mobile/exercise')">在线智能答题</button>
                <button @click="$router.replace('/mobile/lexicon')">在线智能刷词</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {

        };
    },

}
</script>

<style scoped>
.phone-contain {
    background-color: #F1F6FD;
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-width: 360px;
    max-width: 768px;
    height: 100vh;
    overflow-y: scroll;
    margin: 0 auto;
    box-sizing: border-box;
    background-size: cover;
    background-image: url(@/assets/登录炫彩模糊背景.png);
}

.header {
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}

.header:hover {
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    background-color: rgba(255, 255, 255, 0.5);
}

.header-logo {
    width: 40px;
    height: 40px;
    display: flex;
    cursor: pointer;
    border-radius: 5px;
}

.header-title {
    margin-left: 20px;
    margin-right: auto;
}
.header-button {
    margin-left: 20px;
    padding: 0 15px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    border: 2px solid #0079BA;
    box-sizing: border-box;
    color: #0079BA;
    cursor: pointer;
    text-decoration: none;
}

.header-button:hover {
    background-color: #0079BA;
    color: white;
}

.content {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    margin-right: 20px;
    padding: 20px 0;
    padding-bottom: 0;
    border-radius: 5px;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    overflow: hidden;
    box-sizing: border-box;
}

.content img {
    width: 120px;
    height: 120px;
    border-radius: 20px;
}

.content-title {
    font-size: 30px;
    margin-top: 20px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(45deg, #175D88, #45B3F9, #337fae);
    display: flex;
    align-items: center;
    color: transparent;
    -webkit-background-clip: text;
}

.content-tip {
    margin-top: 20px;
    display: flex;
    align-items: center;
    color: #a7b0c7;
    padding: 0 20px;
    text-align: justify;
    margin-bottom: auto;
    line-height: 25px;
    font-size: 12px;
    margin-bottom: 20px;
}

.content-button {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 20px 10px;
    background-color: white;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
}

button {
    flex: 1;
    color: #2689B7;
    margin: 0 10px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    font-size: 12px;
    border: 3px solid #2689B7;
    font-family: "mathspie";
}

button:hover {
    color: white;
    background-color: #2689B7;
}
</style>