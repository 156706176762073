<template>
    <div class="contain manage-contain">
        <HeaderManage></HeaderManage>
        <div class="main">
            <NavigateMenu active="/manage/exercise"></NavigateMenu>
            <div class="main-contain" style="margin-right: 10px;">
                <AiExerciseBox></AiExerciseBox>
            </div>
            <div class="main-contain" style="margin-left: 10px;">
                <AiLexiconBox></AiLexiconBox>
            </div>
        </div>
    </div>
</template>

<script>
import AiExerciseBox from '@/components/manages/AiExerciseBox.vue';
import AiLexiconBox from '@/components/manages/AiLexiconBox.vue';
import HeaderManage from '@/components/manages/HeaderManage.vue';
import NavigateMenu from '@/components/manages/NavigateMenu.vue';

export default {
    data() {
        return {

        };
    },
    methods: {

    },
    beforeCreate() {

    },
    mounted() {
    },
    components: { HeaderManage, NavigateMenu, AiExerciseBox, AiLexiconBox }
}
</script>

<style scoped>
.main {
    box-sizing: border-box;
    display: flex;
    min-height: calc(100vh - 60px);
}

.main-contain {
    flex: 1;
    height: 600px;
    margin: 20px;
    flex-basis: 0;
}

</style>