<template>
    <div class="login-box">
        <img src="@/assets/默认头像.png" alt="">
        <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" class="login-form">
            <el-form-item label="管理员账号" prop="username">
                <el-input type="text" v-model="form.username" autocomplete="off" placeholder="填写迷你英语单词猫管理员账号"></el-input>
            </el-form-item>
            <el-form-item label="管理员密码" prop="password">
                <el-input type="password" v-model="form.password" autocomplete="off"
                    placeholder="填写迷你英语单词猫管理员密码"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" class="stretch-button" @click="submitForm('form')">登录管理员端</el-button>
                <el-button @click="forgetPassword">忘记密码</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import Api from '@/apis';
export default {
    data() {
        var validateUsername = (rule, value, callback) => {
            if (value === '') {
                return callback(new Error('填写迷你英语单词猫管理员账号'));
            } else {
                return callback();
            }
        };
        var validatePassword = (rule, value, callback) => {
            if (value === '') {
                return callback(new Error('填写迷你英语单词猫管理员密码'));
            } else {
                return callback();
            }
        };
        return {
            form: {
                username: '',
                password: ''
            },
            rules: {
                username: [
                    { validator: validateUsername, trigger: 'blur' }
                ],
                password: [
                    { validator: validatePassword, trigger: 'blur' }
                ],
            }
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    Api.manageLogin(this.form).then(res => {
                        if (res.data.code == 200) {
                            sessionStorage.setItem("license", JSON.stringify(res.data.data))
                            this.$message.success(res.data.message);
                            this.$router.replace('/manage/problem')
                        } else {
                            this.form = { username: '', password: '' }
                            this.$message.error(res.data.message);
                        }
                    }).catch(err => {
                        console.log(err)
                        this.form = { username: '', password: '' }
                        this.$message.warning("服务器异常");
                    })
                } else {
                    return false;
                }
            });
        },
        forgetPassword() {
            this.$alert('联系预醒网络官方邮箱：edgehacker@willwaking.com', '忘记密码解决方案', {
                confirmButtonText: '确定'
            })
        }
    }
}
</script>

<style scoped>
.login-box {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    border-radius: 5px;
    height: 420px;
    width: 500px;
}

img {
    width: 100px;
    height: 100px;
    display: flex;
    cursor: pointer;
    border-radius: 5px;
    margin: 40px auto;
    box-sizing: border-box;
    border: 5px solid #FFFFFF;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    border-radius: 50px;
}

.login-form {
    margin: 20px;
}

.el-form-item {
    margin-bottom: 40px;
}
</style>