import moment from "moment/moment";
let Tool = {
    formatSecond(time) {
        if (time <= 0) { return '00:00:00' }
        const totalMilliseconds = time * 1000;
        const duration = moment.duration(totalMilliseconds);

        const minutes = Math.floor(duration.asMinutes());
        const seconds = Math.floor(duration.asSeconds()) % 60;
        const milliseconds = Math.floor((duration.asMilliseconds() % 1000) / 10);

        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
        const formattedMilliseconds = String(milliseconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
    },
    userNickAvatarFormat(uid) {
        if (uid) {
            return `https://q4.qlogo.cn/headimg_dl?dst_uin=${uid}&spec=640`
        } else {
            return `@/assets/默认头像.png`
        }
    },
    generateRandomString(length) {  
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';  
        const charactersLength = characters.length;  
        for (let i = 0; i < length; i++) {  
            result += characters.charAt(Math.floor(Math.random() * charactersLength));  
        }  
        return result;  
    }
}

export default Tool