import Vue from 'vue'
import VueRouter from "vue-router"
Vue.use(VueRouter)

import Index from "@/pages/Index"
import Mobile from "@/pages/Mobile"
import Login from "@/pages/manages/Login"
import Problem from "@/pages/manages/Problem"
import Ai from "@/pages/manages/Ai"
import MobileExercise from "@/pages/mobiles/MobileExercise"
import MobileLexicon from "@/pages/mobiles/MobileLexicon"
import Exercise from "@/pages/manages/Exercise"
import Profile from "@/pages/manages/Profile"
import OpenExercise from "@/pages/OpenExercise"
import OpenLexicon from "@/pages/OpenLexicon"
import OpenChampion from "@/pages/OpenChampion"

const routes = [
    {
        path: "/",
        component: Index
    },
    {
        path: "/mobile",
        component: Mobile
    },
    {
        path: "/mobile/exercise",
        component: MobileExercise
    },
    {
        path: "/mobile/lexicon",
        component: MobileLexicon
    },
    {
        path: "/champion",
        component: OpenChampion
    },
    {
        path: "/exercise",
        component: OpenExercise
    },
    {
        path: "/lexicon",
        component: OpenLexicon
    },
    {
        path: "/login",
        component: Login
    },
    {
        path: "/manage",
        redirect: "/manage/problem"
    },
    {
        path: "/manage/problem",
        component: Problem
    },
    {
        path: "/manage/ai",
        component: Ai
    },
    {
        path: "/manage/exercise",
        component: Exercise
    },
    {
        path: "/manage/profile",
        component: Profile
    },
]
const router = new VueRouter({
    routes,
    mode: "history"
})

router.beforeEach((to, from, next) => {
    const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (isMobile && to.path.startsWith('/mobile')) {
        next();
    } else if (isMobile && !to.path.startsWith('/mobile')) {
        next({ path: '/mobile' });
    } else if (!isMobile && to.path.startsWith('/mobile')) {
        next({ path: '/' });
    } else {
        if (to.path.startsWith('/manage')) {
            if (!sessionStorage.getItem('license')) {
                next('/login');
            } else {
                next();
            }
        } else {
            next();
        }
    }
});

export default router