<template>
    <div class="trace-box">
        <div class="trace-box-item" v-for="item in problemList" :key="item.serial">
            <span>第 {{ formatDoubleIndex(item.serial) }} 题</span>
            <span class="trace-box-item-result-blank" v-if="item.state == 'BLANK'">需要回答</span>
            <span class="trace-box-item-result-right" v-if="item.state == 'RIGHT'">回答正确</span>
            <span class="trace-box-item-result-error" v-if="item.state == 'ERROR'">回答错误</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['problemList'],
    data() {
        return {
            
        }
    },
    methods: {
        formatDoubleIndex(value) {
            return (value + '').padStart(2, '0')
        }
    }
}
</script>

<style scoped>
.trace-box {
    width: 300px;
    height: 600px;
    background-color: #FFFFFF99;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 20px;
    padding-bottom: 10px;
    overflow-y: scroll;
    box-sizing: border-box;
}

.trace-box-item  {
    width: 260px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 20px;
    font-size: 12px;
    background-color: white;
    color: #30353F;
    box-shadow: 0 2px 8px 0 rgba(99,99,99,.1);
}

.trace-box-item-result-right {
    color: #0DBC79;
}

.trace-box-item-result-error {
    color: #F88067;
}

.trace-box-item-result-blank {
    color: #B0BACB;
}
</style>