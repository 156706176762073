<template>
    <div class="header">
        <img class="header-logo" src="@/assets/图标.jpg" alt="" @click="navigateIndex">
        <div class="header-title" @click="navigateIndex">迷你英语单词猫</div>
        <div class="login-info" v-if="user" @click="navigateManageProfile">
            <img class="login-info-img"  :src="userAvatarFormat(user.username)" alt="">
            <div>管理员：{{ user.username }}</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: {}
        }
    },
    props: {
        title: {
            default: "旗舰店"
        }
    },
    methods: {
        navigateIndex() {
            if (this.$route.path !== '/') {
                this.$router.push('/');
            }
        },
        navigateManageProfile() {
            if (this.$route.path !== '/manage/profile') {
                this.$router.push('/manage/profile');
            }
        },
        userAvatarFormat(qq) {
            return `https://q4.qlogo.cn/headimg_dl?dst_uin=${qq}&spec=640`
        },
    },
    mounted() {
        this.user = JSON.parse(sessionStorage.getItem("license"))
    }
}
</script>


<style scoped>
.header {
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    background-color: #262F3E;
    z-index: 1;
    position: sticky;
    top: 0;
}

.header:hover {
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
}

.header-logo {
    width: 40px;
    height: 40px;
    display: flex;
    cursor: pointer;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
}

.header-title {
    margin-left: 20px;
    margin-right: auto;
    cursor: pointer;
    color: #FFFFFF;
}

.login-info {
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #FFFFFF;
    cursor: pointer;
    margin-left: 20px;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    color: #0079BA;
}

.login-info-img {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    box-sizing: border-box;
    display: block;
    margin: 5px;
    overflow: hidden;
}

.login-info div {
    padding: 0 20px;
}
</style>