<template>
    <div class="contain manage-contain">
        <HeaderManage></HeaderManage>
        <div class="main">
            <NavigateMenu active="/manage/ai"></NavigateMenu>
            <div class="main-contain">
                <AiChatBox v-on:fill="fillProblemParsent"></AiChatBox>
            </div>
            <div class="problem-insert-box">
                <el-form ref="form" :model="form">
                    <el-form-item label="题目内容">
                        <el-input class="custom-textarea" :style="{ textAlign: 'justify' }" type="textarea"
                            placeholder="请填写题目内容" :autosize="{ minRows: 3, maxRows: 12 }" v-model="form.content"
                            resize="none" maxlength=1024></el-input>
                    </el-form-item>

                    <el-form-item label="题目选项">
                        <el-input style="margin-bottom: 10px;" v-model="form.option[0]" clearable placeholder="请填写题目选项"
                            maxlength=256></el-input>
                        <el-input style="margin-bottom: 10px;" v-model="form.option[1]" clearable placeholder="请填写题目选项"
                            maxlength=256></el-input>
                        <el-input style="margin-bottom: 10px;" v-model="form.option[2]" clearable placeholder="请填写题目选项"
                            maxlength=256></el-input>
                        <el-input style="margin-bottom: 10px;" v-model="form.option[3]" clearable placeholder="请填写题目选项"
                            maxlength=256></el-input>
                    </el-form-item>

                    <el-form-item label="题目答案">
                        <el-select class="custom-select" style="width: 100%;" maxlength=256 v-model="form.answer"
                            placeholder="请选择题目答案">
                            <el-option v-for="(option, index) in form.option" :label="option"
                                :value="option ? option : '需要填写选项内容'" :key="index"></el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="题目解析">
                        <el-input class="custom-textarea" type="textarea" placeholder="请填写题目解析"
                            :autosize="{ minRows: 3, maxRows: 12 }" v-model="form.solution" resize="none"
                            maxlength=1024></el-input>
                    </el-form-item>
                </el-form>
                <div class="el-form-bottom">
                    <el-button type="primary" @click="insertProblem" :disabled="loading">保存题目</el-button>
                    <el-button type="danger" @click="deleteProblemData(true)">清空</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/apis';
import AiChatBox from '@/components/manages/AiChatBox.vue';
import HeaderManage from '@/components/manages/HeaderManage.vue';
import NavigateMenu from '@/components/manages/NavigateMenu.vue';

export default {
    data() {
        return {
            user: {},
            form: {
                content: "",
                option: ['', '', '', ''],
                answer: "",
                solution: ""
            },
            loading: false
        }
    },
    methods: {
        deleteProblemData(openMessage = false) {
            this.form = {
                content: "",
                option: ['', '', '', ''],
                answer: "",
                solution: ""
            }
            if (openMessage) {
                this.$message.success("清空题目填写数据成功")
            }
        },
        deconstructProblem(problemData) {
            try {
                const result = {};
                problemData = problemData.replace("\n\nA.", "\nA.").replace("\n\nB.", "\nB.").replace("\n\nC.", "\nC.").replace("\n\nD.", "\nD.")
                const questionMatch = problemData.match(/题目：(.*?)(?=\nA)/s);
                if (questionMatch) {
                    result.content = questionMatch[1].trim();
                }

                const optionsMatch = problemData.match(/A\.\s(.*)\nB\.\s(.*)\nC\.\s(.*)\nD\.\s(.*)/);
                if (optionsMatch) {
                    result.option = [optionsMatch[1], optionsMatch[2], optionsMatch[3], optionsMatch[4]];
                }

                const answerMatch = problemData.match(/答案：[A-D]\.\s(.*)/);
                if (answerMatch) {
                    result.answer = answerMatch[1].trim();
                }

                const solutionMatch = problemData.match(/解析：(.*)/s);
                if (solutionMatch) {
                    result.solution = solutionMatch[1].trim();
                }

                if (!result.content || result.option.length != 4 || !result.answer || !result.solution) {
                    this.$message.error("快速填充题目错误")
                    return
                } else {
                    this.form = result
                    this.form = JSON.parse(JSON.stringify(this.form))
                    this.$message.success("快速填充题目成功")
                }
            } catch {
                this.$message.error("快速填充题目错误")
            }
        },
        fillProblemParsent(problemData) {
            this.deconstructProblem(problemData)
        },
        insertProblem() {
            if (!this.form.content || this.form.option.length != 4 || !this.form.answer || !this.form.solution) {
                this.$message.warning("题目信息空缺")
                return
            }
            if (this.loading) {
                return
            } else {
                this.loading = true
            }
            Api.manageProblemInsert({
                ...this.form,
                username: this.user.username
            }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success(res.data.message)
                    this.deleteProblemData()
                } else {
                    this.$message.error(res.data.message)
                }
                this.loading = false
            }).catch(() => {
                this.$message.warning("服务器异常")
                this.loading = false
            })
        }
    },
    beforeCreate() {

    },
    beforeDestroy() {
        
    },
    mounted() {
        this.user = JSON.parse(sessionStorage.getItem("license"))
    },
    components: { HeaderManage, NavigateMenu, AiChatBox }
}
</script>

<style scoped>
.main {
    box-sizing: border-box;
    display: flex;
    min-height: calc(100vh - 60px);
}

.main-contain {
    flex: 1;
    height: 600px;
    margin: 20px;
    margin-right: 0;
}

.problem-insert-box {
    margin: 20px;
    background-color: #FAFBFE;
    width: 400px;
    height: 600px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
}

.el-form {
    padding: 20px;
    box-sizing: border-box;
    height: calc(600px - 80px);
    overflow-y: scroll;
    padding-bottom: 0;
}


.el-form-item {
    margin-bottom: 20px;
}

.el-form-bottom {
    background-color: rgb(255, 255, 255);
    height: 40px;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    padding: 20px;
    padding-bottom: 20px;
    text-align: right;
}

.el-input {
    text-align: justify;
}

.custom-textarea::v-deep .el-textarea__inner {
    text-align: justify;
    line-height: 20px;
}</style>