import axios from 'axios'
import CryptoJS from 'crypto-js';

axios.defaults.baseURL = "https://cat.mslmsxp.com/server"

axios.defaults.timeout = 30000

axios.interceptors.request.use(function (config) {
    if (sessionStorage.getItem("license")) {
        let user = JSON.parse(sessionStorage.getItem("license"))
        config.headers.License = user.username
        config.headers.Authorization = CryptoJS.MD5(user.password).toString()
    }
    return config
}, function (error) {
    return Promise.reject(error)
});


let Api = {
    championTime(data) {
        return axios.get("/champion/time", data)
    },
    championRandom(data) {
        return axios.get("/champion/random", data)
    },
    customTranslate(data) {
        return axios.get(`https://api.oioweb.cn/api/txt/QQFanyi?sourceText=${data.sourceText}`)
    },
    ExerciseProblemRandom(data) {
        return axios.post("/exercise/problem/random", data)
    },
    manageLogin(data) {
        return axios.post("/manage/login", data)
    },
    manageRegister(data) {
        return axios.post("/manage/register", data)
    },
    manageUserSelect(data) {
        return axios.post("/manage/user/select", data)
    },
    manageUserDelete(data) {
        return axios.post("/manage/user/delete", data)
    },
    manageAiChat(data) {
        return axios.post("/manage/ai/chat", data)
    },
    manageAiCreate(data) {
        return axios.post("/manage/ai/create", data)
    },
    manageProblemInsert(data) {
        return axios.post("/manage/problem/insert", data)
    },
    manageProblemSelect(data) {
        return axios.post("/manage/problem/select", data)
    },
    manageProblemDelete(data) {
        return axios.post("/manage/problem/delete", data)
    },
    manageProblemRandom(data) {
        return axios.post("/manage/problem/random", data)
    },
}

export default Api
