<template>
    <div class="contain">
        <HeaderMenu></HeaderMenu>
        <div class="main">
            <LoginBox></LoginBox>
        </div>
        <BottomMenu></BottomMenu>
    </div>
</template>

<script>
import HeaderMenu from '@/components/HeaderMenu.vue';
import BottomMenu from '@/components/BottomMenu.vue';
import LoginBox from '@/components/LoginBox.vue'

export default {
    data() {
        return {

        };
    },
    beforeCreate() {

    },
    mounted() {
    },
    components: { HeaderMenu, BottomMenu, LoginBox }
}
</script>

<style scoped>
.main {
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    min-height: calc(100vh - 60px);
    justify-content: center;
    align-items: center;
}
</style>