<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    name: 'App',
}
</script>

<style>
@font-face {
    font-family: "mathspie";
    src: url("./assets/mathspie.ttf"), url("./assets/mathspie.ttf"), url("./assets/mathspie.ttf");
}

@font-face {
    font-family: "english";
    font-weight: 900;
    src: url("//at.alicdn.com/wf/webfont/FT27mp9nzRgi/u4tqyA6SJfpK.woff2") format("woff2"), url("//at.alicdn.com/wf/webfont/FT27mp9nzRgi/yCoJWSqDnPUa.woff") format("woff");
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    font-size: 14px;
    font-family: "mathspie";
}
td {
    flex-shrink: 1;
}

.contain {
    width: 100vw; 
    height: 100vh; 
    min-width: 1280px; 
    max-width: 1920px;
    max-height: 1080px;
    min-height: 600px;
    margin: 0 auto;
    color: rgb(0, 0, 0);
    background-size: cover;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    position: relative;
    background-size: cover;
    background-image: url(@/assets/登录炫彩模糊背景.png);
}

.manage-contain {
    background-image: none;
    background-color: #F3F6FD;
}

div::-webkit-scrollbar {
    display: none;
}

form::-webkit-scrollbar {
    display: none;
}

textarea::-webkit-scrollbar {
    display: none;
}


</style>
