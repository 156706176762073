<template>
    <div class="champion-user-box">
        <div class="champion-user" v-for="item in userList" :key="item.uid">
            <div v-if="item.uid">
                <img v-if="item.state == 'BLANK'" class="champion-user-image blank" :src="userNickAvatarFormat(item.uid)" alt="">
                <img v-if="item.state == 'RIGHT'" class="champion-user-image right" :src="userNickAvatarFormat(item.uid)" alt="">
                <img v-if="item.state == 'ERROR'" class="champion-user-image error" :src="userNickAvatarFormat(item.uid)" alt="">
            </div>

            <img v-else class="champion-user-none" src="@/assets/默认头像.png">

            <div v-if="item.uid" class="champion-user-tip">{{ item.point }}</div>
            <div v-else class="champion-user-tip" style="color: #EDF4FD">空位</div>
        </div>
        <div class="champion-user" v-for="(item, index) in 30 - userList.length" :key="index">
            <img v-if="item.uid" class="champion-user-image" :src="userNickAvatarFormat(item.uid)" alt="">
            <img v-else class="champion-user-none" src="@/assets/默认头像.png">
            <div v-if="item.uid" class="champion-user-tip">{{ item.point }}</div>
            <div v-else class="champion-user-tip" style="color: #EDF4FD">空位</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['userList'],
    data() {
        return {

        }
    },
    methods: {
        userNickAvatarFormat(uid) {
            return `https://q4.qlogo.cn/headimg_dl?dst_uin=${uid}&spec=640`
        },
    },
    mounted() {

    }
}
</script>

<style scoped>
.champion-user-box {
    width: 300px;
    height: 500px;
    background-color: #FFFFFF99;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 15px;
    overflow-y: scroll;
    box-sizing: border-box;
}

.champion-user {
    width: 80px;
    margin: 5px;
    padding: 15px 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
}

.champion-user-image {
    width: 40px;
    height: 40px;
    display: block;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 2px;
}

.blank {
    border: 3px solid #EDF4FD;
}

.right {
    border: 3px solid #0DBC79;
}

.error {
    border: 3px solid #F88067;
}

.champion-user-none {
    width: 40px;
    height: 40px;
    display: block;
    box-sizing: border-box;
    border-radius: 25px;
    border: 3px solid #EDF4FD;
    padding: 2px;
}

.champion-user-tip {
    margin-top: 10px;
    height: 20px;
    font-size: 15px;
    color: #454A4E;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>